import React, { useContext, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

import "./DefaultLayout.scss";
import { UserContext } from "context/UserContext";
import Sidebar from "components/Sidebar/Sidebar";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Loader from "components/Loader/Loader";

const DefaultLayout = ({ children }) => {
  const location = useLocation();

  const [prevWindowInnerWidth, setPrevWindowInnerWidth] = useState(
    window.innerWidth
  );
  const { minimize, setMinimize, showSidebar, setShowSidebar } =
    useContext(UserContext);

  const handleResize = () => {
    const currentWindowInnerWidth = window.innerWidth;
    if (prevWindowInnerWidth !== currentWindowInnerWidth) {
      if (currentWindowInnerWidth < 1400) {
        setMinimize(true);
      } else {
        setMinimize(false);
      }
      setPrevWindowInnerWidth(currentWindowInnerWidth);
    }
  };

  useLayoutEffect(() => {
    if (
      [
        "/users",
        "/permission",
        "/reports",
        "/settings",
        "/adminpanel",
        "/domain_health",
      ].includes(location.pathname) ||
      location.pathname.startsWith("/candidates/")
    ) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [location]);

  return (
    <Container fluid className="app-container">
      <Loader />
      <Header />

      <Row className="mt-4 p-4 m-0 main-content">
        {showSidebar && !minimize && (
          <Col md={2} sm={12} className="sidebar">
            <Sidebar />
          </Col>
        )}

        <Col md={showSidebar ? (minimize ? 12 : 10) : 12}>{children}</Col>
      </Row>

      <Footer />
    </Container>
  );
};

export default DefaultLayout;
