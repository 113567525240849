import { ChartsColor } from "helpers/Constant";
import { numberWithCommas } from "helpers/ReusableFunctions";
import React, { memo } from "react";
import { Col, Row } from "react-bootstrap";

const MeetingConversionReportNumbers = ({ meetingConversionData }) => {
  const metricsData = [
    [
      {
        label: "Total Emails Sent",
        value: numberWithCommas(meetingConversionData?.total_emails),
      },
      {
        label: "Total Leads",
        value: numberWithCommas(meetingConversionData?.total_leads),
      },
    ],
    [
      {
        label: "Total Replies",
        value: numberWithCommas(meetingConversionData?.replied),
      },
      {
        label: "Total Replies Percentage",
        value: `${meetingConversionData?.percent_replied || 0}%`,
      },
    ],
    [
      {
        label: "Total Positive Replies",
        value: numberWithCommas(meetingConversionData?.positive_replies),
      },
      {
        label: "Total Positive Replies Percentage",
        value: `${meetingConversionData?.percent_positive || 0}%`,
      },
    ],
    [
      {
        label: "Total Meetings",
        value: numberWithCommas(meetingConversionData?.num_meetings),
      },
      {
        label: "Total Meetings Percentage",
        value: `${meetingConversionData?.percent_meetings || 0}%`,
      },
    ],
    [
      {
        label: "Total Pitches",
        value: numberWithCommas(meetingConversionData?.num_pitches),
      },
      {
        label: "Total Pitches Ratio",
        value: meetingConversionData?.pitches_per_meeting,
      },
    ],
    [
      {
        label: "Target Pitches",
        value: numberWithCommas(meetingConversionData?.num_target_pitches),
      },
      {
        label: "Total Pitches Ratio",
        value: meetingConversionData?.target_pitches_per_meeting,
      },
    ],
  ];
  return (
    <div className="card card-layout w-100">
      <Row className="gap-2 gap-md-0 g-4">
        {metricsData?.map((data, index) => (
          <Col key={index}>
            <div
              className="card-layout h-100 d-flex flex-row flex-md-column justify-content-around rounded-5 align-items-center m-0"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              {data.map((item, i) => (
                <div
                  className="d-flex flex-column justify-content-center align-items-center  align-self-baseline align-self-sm-auto w-100"
                  key={`${item}_${i}`}
                >
                  <h1
                    className="card-title fw-bolder"
                    style={{ color: ChartsColor[index] }}
                  >
                    {item?.value || 0}
                  </h1>
                  <h6 className="metrics-label text-center">{item?.label}</h6>
                </div>
              ))}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default memo(MeetingConversionReportNumbers);
