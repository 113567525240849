import LineRecharts from "components/Charts/LineRecharts";
import ModalBox from "components/Modal/ModalBox";
import React, { memo, useState } from "react";
import { Col, Row } from "react-bootstrap";

const lineDataConst = [
  {
    ratioType: "emails",
    name: "Emails Sent & Leads",
    labels: [{ name: "Total Emails Sent" }, { name: "Total Leads" }],
    keys: ["emailsSent", "leads"],
  },
  {
    ratioType: "pitches",
    name: "Positive Replies, Meetings & Pitches",
    labels: [
      { name: "Total Positive Replies" },
      { name: "Total Meetings" },
      { name: "Total Pitches" },
      { name: "Target Pitches" },
    ],
    keys: ["positiveReplies", "meetings", "pitches", "targetPitches"],
  },
  {
    ratioType: "percentagesPitches",
    name: "Percentages",
    labels: [
      { name: "Total Positive Replies Percentage" },
      { name: "Total Meetings Percentage" },
    ],
    keys: ["percentPositive", "percentMeetings"],
    isYAxisContainsPercentData: true,
  },
  {
    ratioType: "pitchesRatio",
    name: "Target Pitches & Total Pitches",
    labels: [{ name: "Total Pitches" }, { name: "Target Pitches" }],
    keys: ["pitchesRatio", "targetPitchesRatio"],
  },
  {
    ratioType: "percentagesReplied",
    name: "Total Reply Percentage",
    labels: [{ name: "Total Reply Percentage" }],
    keys: ["percentReplied"],
    isYAxisContainsPercentData: true,
  },
];

const MeetingsConversionGraphs = ({ lineData }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedLineIndex, setSelectedLineIndex] = useState(0);

  const openModal = (selectedIndex) => {
    setSelectedLineIndex(selectedIndex);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedLineIndex("");
  };

  return (
    <>
      <div className="card card-layout w-100">
        <Row className="gap-2 gap-md-0 g-4">
          {lineDataConst?.map((line, i) => {
            return (
              <Col
                key={`${i}_${line?.ratioType}`}
                className={`col-12 ${i <= 2 ? "col-md-4" : "col-md-6"}`}
              >
                <div
                  className="card-layout h-100 w-100 m-0"
                  style={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                >
                  <LineRecharts
                    lineData={lineData?.[i]}
                    datakeys={line?.keys}
                    dataLabels={line?.labels}
                    isExpandAllowed
                    openModal={openModal}
                    selectedLineIndex={i}
                    setSelectedLineIndex={setSelectedLineIndex}
                    isYAxisContainsPercentData={
                      !!line?.isYAxisContainsPercentData
                    }
                    yAxisLabelName={line?.name}
                    isSmall
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </div>

      <ModalBox
        title={lineDataConst?.[selectedLineIndex]?.name}
        show={showModal}
        onHide={closeModal}
        content={
          <LineRecharts
            lineData={lineData?.[selectedLineIndex]}
            datakeys={lineDataConst?.[selectedLineIndex]?.keys}
            dataLabels={lineDataConst?.[selectedLineIndex]?.labels}
            isOpenModal={showModal}
            selectedLineIndex={selectedLineIndex}
            isYAxisContainsPercentData={
              !!lineDataConst?.[selectedLineIndex]?.isYAxisContainsPercentData
            }
            yAxisLabelName={lineDataConst?.[selectedLineIndex]?.name}
          />
        }
      />
    </>
  );
};

export default memo(MeetingsConversionGraphs);
