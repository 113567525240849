import { dateOptions } from "helpers/Constant";
import AxiosClient from "./AxiosClient";

export const getPositionRoles = async () => {
  const data = await AxiosClient.post("", {
    query: `query { 
            position_role_filters {
              focuses
              id
            }
          }`,
  });
  return data;
};

export const getClientsList = async (language) => {
  const data = await AxiosClient.post("", {
    query: `query ClientFilters {
      client_filters(locale: ${language}) {
        id
        name
      }
    }`,
  });
  return data;
};

export const getEmployeeList = async () => {
  const data = await AxiosClient.post("", {
    query: `query EmployeeFilters {
      employee_filters {
        id
        first_name
        last_name
        role
      }
    }`,
  });
  return data;
};

export const getIndustryList = async () => {
  const data = await AxiosClient.post("", {
    query: `query { 
            industry_filters {
              industries
              your_industry_id
            }
          }`,
  });
  return data;
};

export const getLoggedInUserData = async (language = "en") => {
  const data = await AxiosClient.post("", {
    query: `query { 
            me(locale: ${language}) {
              id
              email
              is_admin
              saved_reports {
                id
                payload
                created
                updated
                version
              }
              organization {
                id
                industry
                name
              }
              profile {
                confirmation_status
                created
                email
                enabled
                first_name
                id
                is_admin
                last_login
                last_name
                phone_number
                title
                updated
              }
            }
          }`,
  });
  return data;
};

export const getDetailedApplicationData = async (
  filterData,
  Clients,
  presentationStartStatus
) => {
  const pitchQuery = filterData?.PitchResponse
    ? `pitch_response: ${filterData?.PitchResponse},`
    : ``;
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData.Positions.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData.Speciality.value}",`
    : "";
  const startDate = filterData?.startDate
    ? `start_date: "${filterData.startDate.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData.endDate.toISOString()}",`
    : "";
  const clientQuery = Clients?.value ? `client_id: "${Clients.value}",` : "";
  // const companyStage = filterData?.Company?.value
  //   ? `company_stage: ${filterData?.Company?.value}`
  //   : "";

  const presentationQuery = presentationStartStatus
    ? `presenting_as_external: ${presentationStartStatus},`
    : "";

  const data = await AxiosClient.post("", {
    query: `query {
      reports {
          applicants( 
            ${presentationQuery}
            filters: {
            ${pitchQuery}
            ${positionQuery}
            ${focusQuery}
            ${startDate}
            ${endDate}
            ${clientQuery}
          }) {
              data {
                current_employer
                current_title
                first_name
                last_name
                app_position {
                  client_name
                  current_stage
                  focus
                  last_updated
                  position_name
                  position_id
                  role
                  pitch_response {
                    reason
                    response
                  }
                  candidate_manager {
                    id
                    first_name
                    last_name
                    email
                  }
                  stage_history {
                    comments {
                      created_at
                      text
                    }
                    created_at
                    other_applications
                    stage
                    updated_at
                  }
                } 
                birthday
                education_history
                english
                facebook_url
                github_url
                japanese
                kanji_first
                kanji_last
                linkedin_url
                salary
                salary_breakdown
                twitter_url
                work_history
                candidate_id
                last_activity_date
              }
            }
          }
        }`,
  });

  return data;
};

export const getActiveApplicantData = async (
  filterData,
  Clients,
  presentationStartStatus,
  pagination
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";

  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";

  const presentationQuery = presentationStartStatus
    ? `presenting_as_external: ${presentationStartStatus},`
    : "";

  const StageManagersFinal = Array.isArray(filterData?.StageManagers)
    ? filterData?.StageManagers?.map((val) => val?.value)
    : [];
  const StageManagersQuery =
    StageManagersFinal.length > 0 ? `stage:"${StageManagersFinal}",` : "";

  const data = await AxiosClient.post("", {
    query: `query ActivePipeline {  
            reports {
              active_pipeline( 
              ${presentationQuery}
              filters: {
              ${positionQuery}
              ${focusQuery}
              ${ClientQuery} 
              ${StageManagersQuery}}
              page: {
                limit: ${pagination?.limit},
                offset: ${pagination?.offset},
              }
              ) {
                count
                has_more
                data {
                  current_employer
                  current_title
                  first_name
                  last_name
                  app_position {
                    client_name
                    current_stage
                    focus
                    last_updated
                    position_id
                    position_name
                    role
                    pitch_response {
                      reason
                      response
                    }
                    offer_details {
                      fee
                      salary
                      start_date
                    }
                    candidate_manager {
                      id
                      first_name
                      last_name
                      email
                    }
                    stage_history {
                      comments {
                        created_at
                        text
                      }
                      created_at
                      other_applications
                      stage
                      updated_at
                    }
                  }
                  birthday
                  education_history
                  english
                  facebook_url
                  github_url
                  japanese
                  kanji_first
                  kanji_last
                  linkedin_url
                  salary
                  salary_breakdown
                  twitter_url
                  work_history
                  candidate_id
                  last_activity_date
                }
              }
            }
          }`,
  });

  return data;
};

export const getCandidatePipelineData = async (
  filterData,
  Clients,
  pagination
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];
  const BusinessManagersQuery =
    BusinessManagersFinal.length > 0
      ? `bm_ids: [${BusinessManagersFinal}],`
      : "";
  const CandidateManagersQuery =
    CandidateManagersFinal.length > 0
      ? `cm_ids: [${CandidateManagersFinal}],`
      : "";
  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";

  const StageManagersFinal = Array.isArray(filterData?.StageManagers)
    ? filterData?.StageManagers?.map((val) => val?.value)
    : [];
  const StageManagersQuery =
    StageManagersFinal.length > 0 ? `stage:"${StageManagersFinal}",` : "";

  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const candidatePipeline = Array.isArray(filterData?.businessManagers)
    ? filterData?.businessManagers
    : [];
  const candidatePipelineDashboard =
    candidatePipeline.length > 0 ? `bm_ids:[${candidatePipeline}],` : "";

  const clientPipeline = Array.isArray(filterData?.candidateManagers)
    ? filterData?.candidateManagers
    : [];
  const clientPipelineDashboard =
    clientPipeline.length > 0 ? `cm_ids:[${clientPipeline}],` : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData.startDate.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData.endDate.toISOString()}",`
    : "";

  const filters = `{
      ${positionQuery}
      ${focusQuery}
      ${BusinessManagersQuery}
      ${CandidateManagersQuery}
      ${StageManagersQuery}
      ${ClientQuery}
      ${candidatePipelineDashboard}
      ${clientPipelineDashboard}
      ${clientManagersQuery}
      ${startDate}
      ${endDate}
    }`;

  const reports = pagination?.limit
    ? `reports {
        active_pipeline(
          filters: ${filters},
          page: {
            limit: ${pagination?.limit},
            offset: ${pagination?.offset},
          }) {
          data {
            current_employer
            current_title
            first_name
            last_name
            app_position {
              client_name
              current_stage
              focus
              last_updated
              position_id              
              position_name
              role
              business_manager {
                id
                first_name
                last_name
              }
              candidate_manager {
                id
                first_name
                last_name
                email
              }
              pitch_response {
                reason
                response
              }
              offer_details {
                fee
                salary
                start_date
              }
              stage_history {
                comments {
                  created_at
                  text
                  commenter {
                    id
                    first_name
                    last_name
                  }
                }
                created_at
                other_applications
                stage
                updated_at
              }
            }
            birthday
            education_history
            english
            facebook_url
            github_url
            japanese
            kanji_first
            kanji_last
            linkedin_url
            salary
            salary_breakdown
            twitter_url
            work_history
            outreach_url
            trello_url
            zoho_url
            candidate_id
            last_activity_date
          }
          count
          has_more
        }
      }`
    : "";

  const data = await AxiosClient.post("", {
    query: `{
      dashboard {
        active_pipeline_counts(filters: ${filters}, presenting_as_external: false) {
          total_candidates
          active_interviews
          final_interviews
          offers
          pitching
          ready_for_introduction
          resume_screening
          testing
        }
      }
      ${reports}
    }`,
  });

  return data;
};

export const compareRangeInterval = async (filterData, Clients) => {
  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate?.toLocaleDateString(
        "en-JP",
        dateOptions
      )}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate?.toLocaleDateString(
        "en-JP",
        dateOptions
      )}",`
    : "";
  // const startDate = filterData?.startDate
  //   ? `start_date: "${filterData?.startDate?.toISOString()}"`
  //   : "";
  // const endDate = filterData?.endDate
  //   ? `end_date: "${filterData?.endDate?.toISOString()}"`
  //   : "";
  const count = filterData?.count ? `count: ${filterData?.count},` : ``;
  const unit = filterData?.unit ? `unit: ${filterData.unit},` : ``;
  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";

  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";

  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";

  // const presentationQuery = presentationStartStatus
  //   ? `presenting_as_external: ${presentationStartStatus},`
  //   : "";

  const data = await AxiosClient.post("", {
    query: `query {
      reports {
      pitch_performance_interval(
        filters : {
        ${ClientQuery}
        ${positionQuery}
        ${focusQuery}      
      }, interval: {
        ${count}
        ${unit}
        ${startDate}
        ${endDate}
        }, benchmarks: [company, market, industry]) {
        benchmark
        intervals {
          data {
            percent_applied
            percent_declined
            percent_maybe 
          }
          range {
            interval_end
            interval_start
          }
        }
      }     
    }
    }`,
  });
  return data;
};

export const getPitchPerformance = async (filterData, Clients) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate?.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate?.toISOString()}",`
    : "";
  const companyStageQuery = filterData?.Company?.value
    ? `company_stage: ${filterData?.Company?.value},`
    : "";

  const clientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";
  const industry = filterData?.Industry?.value
    ? `industry: "${filterData?.Industry.value}",`
    : "";
  // const presentationQuery = presentationStartStatus
  //   ? `presenting_as_external: ${presentationStartStatus},`
  //   : "";

  const data = await AxiosClient.post("", {
    query: `query {
      reports {
          pitch_performance(
          benchmarks: [company, market, industry],
          filters: {
            ${positionQuery}
            ${focusQuery}
            ${startDate}
            ${endDate}
            ${companyStageQuery}
            ${industry}
            ${clientQuery}
          }) {
              benchmark
              data {
                  percent_applied
                  percent_declined
                  percent_maybe
                }   
              }
            }
          }`,
  });
  return data;
};

export const getDetailedBarData = async (filterData, Clients) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate?.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate?.toISOString()}",`
    : "";
  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";
  const companyStageQuery = filterData?.Company?.value
    ? `company_stage: ${filterData.Company.value},`
    : "";
  const industry = filterData?.Industry?.value
    ? `industry: "${filterData?.Industry.value}",`
    : "";
  // const presentationQuery = presentationStartStatus
  //   ? `presenting_as_external: ${presentationStartStatus},`
  //   : "";

  const data = await AxiosClient.post("", {
    query: `query {
      reports {
          pitch_declined_reasons(
          benchmarks: [company, market, industry],
          filters: {
            ${positionQuery}
            ${focusQuery}
            ${startDate}
            ${endDate}
            ${companyStageQuery}
            ${industry}
            ${ClientQuery}
          }) {
              benchmark
              data {
                percent_already_applied
                percent_bad_company_impression
                percent_not_interested_role
                percent_not_interested_tech_stack
                percent_not_looking
                percent_prefers_other_company
                percent_salary_concern
                percent_working_language_concern
              }
            }
          }
        }`,
  });
  return data;
};

export const getCandidatePitchHistory = async (candidateId) => {
  const data = await AxiosClient.post("", {
    query: `{ 
      pipelines {
      candidate_pitch_history(candidate_id:${candidateId}) {
      data {
        client_id
        candidate_manager {
          id
          first_name
          last_name
        }
        pitch_response {
          reason
          response
        }
        client_name
        client_name_ja
        created_at
        position_id
        position_name
        position_name_ja
        stage
        updated_at
      }
      }
      }
      }`,
  });
  return data;
};

export const getCandidateActiveApplicationsDetail = async (candidateId) => {
  const data = await AxiosClient.post("", {
    query: `{
      pipelines {
        candidate_active_applications(candidate_id:  ${candidateId}) {
          data {
            candidate_id
            position_id
            created_at
            stage
            id
            text
            pitch_response {
              reason
              response
            }
          }
        }
      }
    }`,
  });
  return data;
};

export const getPositionHistory = async (candidateId, positionId) => {
  const position_id = `"${positionId}"`;
  const data = await AxiosClient.post("", {
    query: `{ 
      pipelines {
          candidate_position_history(candidate_id:${candidateId}, position_id:${position_id}) {
              data {
                  created_at
                  other_applications
                  stage
                  updated_at
                  pitch_response {
                    reason
                    response
                  }
                  comments {
                      commenter {
                          email
                          first_name
                          id
                          last_name
                          role
                      }
                      created_at
                      text
                  }
              }
          }
      }
  }`,
  });

  return data;
};

export const createCustomReport = async (customReport) => {
  const data = await AxiosClient.post("", {
    query: `
    mutation CreateReport($payload: String!){
      save_report(payload : $payload ) {
          id
          payload
          created
          updated
          version
      }
  }`,
    variables: {
      payload: JSON.stringify(customReport),
    },
  });

  return data;
};

export const updateCustomReport = async (id, version, customReport) => {
  const data = await AxiosClient.post("", {
    query: `
     mutation UpdateReport($payload: String!, $id : ID, $version: Int) {
      save_report(payload: $payload, id: $id , version: $version) {
        id
        payload
        created
        updated
        version
      }
    }`,
    variables: {
      payload: JSON.stringify(customReport),
      id: String(id),
      version: version,
    },
  });
  return data;
};

export const deleteCustomReport = async (id) => {
  const data = await AxiosClient.post("", {
    query: `mutation DeleteReport {
      delete_report(id: "${id}")
    }`,
  });
  return data;
};

export const getSuccessKPI = async (
  filterData,
  Clients,
  presentationStartStatus
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];
  const BusinessManagersQuery =
    BusinessManagersFinal.length > 0
      ? `bm_ids: [${BusinessManagersFinal}],`
      : "";
  const CandidateManagersQuery =
    CandidateManagersFinal.length > 0
      ? `cm_ids: [${CandidateManagersFinal}],`
      : "";
  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";

  const StageManagersFinal = Array.isArray(filterData?.StageManagers)
    ? filterData?.StageManagers?.map((val) => val?.value)
    : filterData?.StageManagers?.value
    ? filterData?.StageManagers?.value
    : [];
  const StageManagersQuery =
    StageManagersFinal.length > 0 ? `stage:${StageManagersFinal},` : "";

  const ClientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate?.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate?.toISOString()}",`
    : "";
  const industry = filterData?.Industry?.value
    ? `industry: "${filterData?.Industry.value}",`
    : "";
  const count = filterData?.count ? `count: ${filterData?.count},` : ``;
  const unit = filterData?.unit ? `unit: ${filterData.unit},` : ``;
  const presentationQuery = presentationStartStatus
    ? `presenting_as_external: ${presentationStartStatus},`
    : "";

  const data = await AxiosClient.post("", {
    query: `query {
      reports {
          success_kpi(
            ${StageManagersQuery}  
            ${presentationQuery}         
            interval: { ${startDate} ${endDate} ${unit} ${count}}, filters: { 
            ${positionQuery}
            ${focusQuery}
            ${BusinessManagersQuery}
            ${CandidateManagersQuery}
            ${ClientManagersQuery}
            ${ClientQuery}
            ${industry}
          }
     
          ) {
              benchmark
              intervals {
                  data {
                      success
                      failure
                      pending
                      total
                      percent_failure
                      percent_success
                  }
                  range {
                      interval_start
                      interval_end
                  }
              }
          }
      }
    }`,
  });

  return data;
};

export const getTimeToHire = async (
  filterData,
  Clients,
  presentationStartStatus
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];
  const BusinessManagersQuery =
    BusinessManagersFinal.length > 0
      ? `bm_ids: [${BusinessManagersFinal}],`
      : "";
  const CandidateManagersQuery =
    CandidateManagersFinal.length > 0
      ? `cm_ids: [${CandidateManagersFinal}],`
      : "";
  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";

  const StageManagers1 = filterData?.StageManagers1?.value
    ? filterData?.StageManagers1?.value
    : "";
  const StageManagers2 = filterData?.StageManagers2?.value
    ? filterData?.StageManagers2?.value
    : "";

  const ClientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate?.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate?.toISOString()}",`
    : "";
  const industry = filterData?.Industry?.value
    ? `industry: "${filterData?.Industry.value}",`
    : "";
  const count = filterData?.count ? `count: ${filterData?.count},` : ``;

  const unit = filterData?.unit ? `unit: ${filterData.unit},` : ``;

  const presentationQuery = presentationStartStatus
    ? `presenting_as_external: ${presentationStartStatus},`
    : "";

  const data = await AxiosClient.post("", {
    query: `query {
      reports {
        time_to_hire(                
            interval: { ${startDate} ${endDate} ${count} ${unit}},
            stages : {
              start : ${StageManagers1},
              end : ${StageManagers2} 
            },
            ${presentationQuery}
            filters: { 
            ${positionQuery}
            ${focusQuery}
            ${BusinessManagersQuery}
            ${CandidateManagersQuery}
            ${ClientManagersQuery}
            ${ClientQuery}
            ${industry}
          }
          
          ) {
              benchmark
              intervals {
                data {
                  days
                  hours
                  minutes
                  seconds
                }
                range {
                  interval_end
                  interval_start
                }
              }
            }
      }
    }`,
  });

  return data;
};

export const getSuccessKPICandidatesList = async (
  filterData,
  Clients,
  presentationStartStatus,
  isInternalUser
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];
  const BusinessManagersQuery =
    BusinessManagersFinal.length > 0
      ? `bm_ids: [${BusinessManagersFinal}],`
      : "";
  const CandidateManagersQuery =
    CandidateManagersFinal.length > 0
      ? `cm_ids: [${CandidateManagersFinal}],`
      : "";
  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";

  const StageManagersFinal = Array.isArray(filterData?.StageManagers)
    ? filterData.StageManagers?.map((val) => val?.value)
    : filterData.StageManagers?.value;

  const StageManagersQuery =
    StageManagersFinal.length > 0 ? `stage:${StageManagersFinal},` : "";

  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData.startDate.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData.endDate.toISOString()}",`
    : "";
  const industry = filterData?.Industry?.value
    ? `industry: "${filterData?.Industry.value}",`
    : "";
  const presentationQuery = presentationStartStatus
    ? `presenting_as_external: ${presentationStartStatus},`
    : "";
  const locale = filterData?.language
    ? `locale: ${filterData.language},`
    : `locale: en`;

  const restrictAccessOfCommenterDetail = isInternalUser
    ? "commenter {id first_name last_name }"
    : "";
  const restrictAccessOfInternalSocialLinks = isInternalUser
    ? "outreach_url trello_url zoho_url"
    : "";

  const data = await AxiosClient.post("", {
    query: `query Reports {
      reports {
          success_kpi_candidates_list(
            ${StageManagersQuery}    
            ${presentationQuery}    
            filters:  {
            ${startDate}
            ${endDate}
            ${positionQuery}
            ${industry}
            ${focusQuery}
            ${BusinessManagersQuery}
            ${CandidateManagersQuery}
            ${ClientQuery}
            ${clientManagersQuery}
        }
           ${locale}
        ) {
              current_employer
              current_title
              first_name
              last_name
              app_position {
                client_name
                current_stage
                focus
                last_updated
                position_id              
                position_name
                role
                business_manager {
                  id
                  first_name
                  last_name
                }
                candidate_manager {
                  id
                  first_name
                  last_name
                  email
                }
                pitch_response {
                  reason
                  response
                }
                offer_details {
                  fee
                  salary
                  start_date
                }
                stage_history {
                  comments {
                    created_at
                    text
                   ${restrictAccessOfCommenterDetail}
                  }
                  created_at
                  other_applications
                  stage
                  updated_at
                }
              }
              birthday
              education_history
              english
              facebook_url
              github_url
              japanese
              kanji_first
              kanji_last
              linkedin_url
              salary
              salary_breakdown
              twitter_url
              work_history
              ${restrictAccessOfInternalSocialLinks}
              candidate_id
              last_activity_date
              last_activity_type
          }
      }
  }
  `,
  });

  return data;
};

export const getTimeToHireCandidatesList = async (
  filterData,
  Clients,
  presentationStartStatus,
  isInternalUser
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";

  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];
  const BusinessManagersQuery =
    BusinessManagersFinal.length > 0
      ? `bm_ids: [${BusinessManagersFinal}],`
      : "";
  const CandidateManagersQuery =
    CandidateManagersFinal.length > 0
      ? `cm_ids: [${CandidateManagersFinal}],`
      : "";
  const ClientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";

  const StageManagers1 = filterData?.StageManagers1?.value
    ? filterData?.StageManagers1?.value
    : "";
  const StageManagers2 = filterData?.StageManagers2?.value
    ? filterData?.StageManagers2?.value
    : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate.toISOString()}",`
    : "";
  const industry = filterData?.Industry?.value
    ? `industry: "${filterData?.Industry.value}",`
    : "";

  const locale = filterData?.language
    ? `locale: ${filterData.language},`
    : `locale: en`;

  const presentationQuery = presentationStartStatus
    ? `presenting_as_external: ${presentationStartStatus},`
    : "";

  const restrictAccessOfCommenterDetail = isInternalUser
    ? "commenter {id first_name last_name }"
    : "";
  const restrictAccessOfInternalSocialLinks = isInternalUser
    ? "outreach_url trello_url zoho_url"
    : "";

  const data = await AxiosClient.post("", {
    query: `query Reports {
    reports {
      time_to_hire_candidates_list(         
          stages : {
            start : ${StageManagers1},
            end : ${StageManagers2}
          },
          ${presentationQuery}        
          filters:  {
          ${startDate}
          ${endDate}
          ${positionQuery}
          ${industry}
          ${focusQuery}
          ${ClientQuery}
          ${BusinessManagersQuery}
          ${CandidateManagersQuery}
          ${ClientManagersQuery}
      },         
         ${locale}
      ) {
            current_employer
            current_title
            first_name
            last_name
            time_diff {
              days
              hours
              minutes
              seconds
            }
            app_position {
              client_name
              current_stage
              focus
              last_updated
              position_id              
              position_name
              role
              business_manager {
                id
                first_name
                last_name
              }
              candidate_manager {
                id
                first_name
                last_name
                email
              }
              pitch_response {
                reason
                response
              }
              offer_details {
                fee
                salary
                start_date
              }
            
              stage_history {
                comments {
                  created_at
                  text
                 ${restrictAccessOfCommenterDetail}
                }
                created_at
                other_applications
                stage
                updated_at
              }
            }
            birthday
            education_history
            english
            facebook_url
            github_url
            japanese
            kanji_first
            kanji_last
            linkedin_url
            salary
            salary_breakdown
            twitter_url
            work_history
           ${restrictAccessOfInternalSocialLinks}
            candidate_id
            last_activity_date
        }
    }
}
`,
  });

  return data;
};

export const getRecentlyActiveCandidatesList = async (
  filterData,
  Clients,
  pagination
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];

  const CandidateManagersQuery =
    CandidateManagersFinal.length > 0
      ? `cm_ids: [${CandidateManagersFinal}],`
      : "";
  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";

  const StageManagersFinal = Array.isArray(filterData?.StageManagers)
    ? filterData.StageManagers?.map((val) => val?.value)
    : filterData.StageManagers?.value ?? "";

  const StageManagersQuery =
    StageManagersFinal.length > 0 ? `stage:"${StageManagersFinal}",` : "";

  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData.startDate.toISOString()}",`
    : "";

  const endDate = filterData?.endDate
    ? `end_date: "${filterData.endDate.toISOString()}",`
    : "";

  const filters = `{
    ${StageManagersQuery}        
    ${positionQuery}
    ${focusQuery}
    ${CandidateManagersQuery}
    ${ClientQuery}
    ${clientManagersQuery}
    ${startDate}
    ${endDate}
  }`;

  const data = await AxiosClient.post("", {
    query: `query {
      dashboard {
        recently_active_candidates_counts (filters: ${filters}) {
          active_interviews
          final_interviews
          offers
          pitching
          ready_for_introduction
          resume_screening
          testing
          total_candidates
        }
      }
      reports {
        recently_active_candidates(
          filters: ${filters},
          page: {
            limit: ${pagination?.limit},
            offset: ${pagination?.offset},
          }
        ) { 
            count
            data {
              current_employer
              current_title
              first_name
              last_name
              app_position {
                client_name
                current_stage
                focus
                last_updated
                position_id              
                position_name
                role
                business_manager {
                  id
                  first_name
                  last_name
                }
                candidate_manager {
                  id
                  first_name
                  last_name
                  email
                }
                pitch_response {
                  reason
                  response
                }
                offer_details {
                  fee
                  salary
                  start_date
                }
                stage_history {
                  comments {
                    created_at
                    text
                    commenter {
                      id
                      first_name
                      last_name
                    }
                  }
                  created_at
                  other_applications
                  stage
                  updated_at
                }
              }
              birthday
              education_history
              english
              facebook_url
              github_url
              japanese
              kanji_first
              kanji_last
              linkedin_url
              salary
              salary_breakdown
              twitter_url
              work_history
              outreach_url
              trello_url
              zoho_url
              candidate_id
              last_activity_date
              last_activity_type
            }
            has_more
          }
      }
  }
  `,
  });

  return data;
};

export const getActivePipelineMetricsData = async (
  filterData,
  Clients,
  presentationStartStatus = false
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";

  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";

  const presentationQuery = presentationStartStatus
    ? `presenting_as_external: ${presentationStartStatus},`
    : "";

  const StageManagersFinal = Array.isArray(filterData?.StageManagers)
    ? filterData?.StageManagers?.map((val) => val?.value)
    : [];
  const StageManagersQuery =
    StageManagersFinal.length > 0 ? `stage:"${StageManagersFinal}",` : "";

  const data = await AxiosClient.post("", {
    query: `query ActivePipeline {  
            reports {
              active_pipeline( 
               ${presentationQuery}
            filters: {
            ${positionQuery}
            ${focusQuery}
            ${ClientQuery} 
            ${StageManagersQuery}    
          }) {
                data {                 
                  app_position {                   
                    current_stage
                }
              }
            }
          }
        }`,
  });

  return data;
};

export const getRecentlyActiveCandidatesMetrics = async (
  filterData,
  Clients
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];
  const BusinessManagersQuery =
    BusinessManagersFinal.length > 0
      ? `bm_ids: [${BusinessManagersFinal}],`
      : "";
  const CandidateManagersQuery =
    CandidateManagersFinal.length > 0
      ? `cm_ids: [${CandidateManagersFinal}],`
      : "";
  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";

  const StageManagersFinal = Array.isArray(filterData?.StageManagers)
    ? filterData.StageManagers?.map((val) => val?.value)
    : filterData.StageManagers?.value ?? "";

  const StageManagersQuery =
    StageManagersFinal.length > 0 ? `stage:"${StageManagersFinal}",` : "";

  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData.startDate.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData.endDate.toISOString()}",`
    : "";

  const filters = `{
    ${StageManagersQuery}        
    ${positionQuery}
    ${focusQuery}
    ${BusinessManagersQuery}
    ${CandidateManagersQuery}
    ${ClientQuery}
    ${clientManagersQuery}
    ${startDate}
    ${endDate}
  }`;

  const data = await AxiosClient.post("", {
    query: `query {
      dashboard {
        recently_active_candidates_counts (filters: ${filters}) {
          active_interviews
          final_interviews
          offers
          pitching
          ready_for_introduction
          resume_screening
          testing
          total_candidates
        }
      }
    }`,
  });

  return data;
};

export const getSuccessKpiLeaderboardData = async (
  filterData,
  cmbm_id,
  isCandidateManager
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate?.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate?.toISOString()}",`
    : "";
  const count = filterData?.count ? `count: 0,` : ``;
  const unit = filterData?.unit ? `unit: ${filterData.unit},` : ``;

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];

  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const BusinessManagersQuery = !isCandidateManager
    ? `bm_ids:  [${cmbm_id}],`
    : BusinessManagersFinal.length > 0
    ? `bm_ids: [${BusinessManagersFinal}] ,`
    : "";

  const CandidateManagersQuery = isCandidateManager
    ? `cm_ids: [${cmbm_id}],`
    : CandidateManagersFinal.length > 0
    ? `cm_ids: [${CandidateManagersFinal}],`
    : "";

  const StageManagersFinal = Array.isArray(filterData?.StageManagers)
    ? filterData?.StageManagers?.map((val) => val?.value)
    : filterData?.StageManagers?.value
    ? filterData?.StageManagers?.value
    : [];
  const StageManagersQuery =
    StageManagersFinal.length > 0 ? `stage:${StageManagersFinal},` : "";

  const ClientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const industry = filterData?.Industry?.value
    ? `industry: "${filterData?.Industry.value}",`
    : "";

  const data = await AxiosClient.post("", {
    query: `query {
      reports {
          success_kpi(
            interval: { ${startDate} ${endDate} ${count} ${unit}}, 
            ${StageManagersQuery}
            filters: { 
            ${positionQuery}
            ${focusQuery}
            ${BusinessManagersQuery}
            ${CandidateManagersQuery}
            ${ClientManagersQuery}
            ${industry}            
          }
          ) {
              benchmark
              intervals {
                  data {                   
                      total
                      percent_success
                  }                  
              }
          }
      }
    }`,
  });

  return data;
};

export const getTimeToHireLeaderboardData = async (
  filterData,
  cmbm_id,
  isCandidateManager
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";

  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];

  const BusinessManagersQuery = !isCandidateManager
    ? `bm_ids:  [${cmbm_id}],`
    : BusinessManagersFinal.length > 0
    ? `bm_ids: [${BusinessManagersFinal}] ,`
    : "";

  const CandidateManagersQuery = isCandidateManager
    ? `cm_ids: [${cmbm_id}],`
    : CandidateManagersFinal.length > 0
    ? `cm_ids: [${CandidateManagersFinal}],`
    : "";

  const ClientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const StageManagers1 = filterData?.StageManagers1?.value
    ? filterData?.StageManagers1?.value
    : "";
  const StageManagers2 = filterData?.StageManagers2?.value
    ? filterData?.StageManagers2?.value
    : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate.toISOString()}",`
    : "";
  const industry = filterData?.Industry?.value
    ? `industry: "${filterData?.Industry.value}",`
    : "";

  const locale = filterData?.language
    ? `locale: ${filterData.language},`
    : `locale: en`;

  const data = await AxiosClient.post("", {
    query: `query Reports {
    reports {
      time_to_hire_candidates_list(         
          stages : {
            start : ${StageManagers1},
            end : ${StageManagers2}
          },    
          filters:  {
          ${startDate}
          ${endDate}
          ${positionQuery}
          ${industry}
          ${focusQuery}
          ${BusinessManagersQuery}
          ${CandidateManagersQuery}
          ${ClientManagersQuery}
      },         
         ${locale}
      ) {
            first_name
            time_diff {
              days
              hours
              minutes
              seconds
            }
        }
    }
}
`,
  });

  return data;
};

export const getRecentlyActiveCandidatesLeaderboardData = async (
  filterData,
  cmbm_id,
  isCandidateManager
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];

  const BusinessManagersQuery = !isCandidateManager
    ? `bm_ids:  [${cmbm_id}],`
    : BusinessManagersFinal.length > 0
    ? `bm_ids: [${BusinessManagersFinal}] ,`
    : "";

  const CandidateManagersQuery = isCandidateManager
    ? `cm_ids: [${cmbm_id}],`
    : CandidateManagersFinal.length > 0
    ? `cm_ids: [${CandidateManagersFinal}],`
    : "";

  const StageManagersFinal = Array.isArray(filterData?.StageManagers)
    ? filterData.StageManagers?.map((val) => val?.value)
    : filterData.StageManagers?.value ?? "";

  const StageManagersQuery =
    StageManagersFinal.length > 0 ? `stage:"${StageManagersFinal}",` : "";

  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData.startDate.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData.endDate.toISOString()}",`
    : "";

  const data = await AxiosClient.post("", {
    query: `query {
    reports {
      recently_active_candidates(
        filters:  {
          ${StageManagersQuery}        
          ${positionQuery}
          ${focusQuery}
          ${BusinessManagersQuery}
          ${CandidateManagersQuery}
          ${clientManagersQuery}
          ${startDate}
          ${endDate}
      }
      )  {    
          first_name 
          }         
        }
}
`,
  });

  return data;
};

export const getCandidatePipelineLeaderboardData = async (
  filterData,
  cmbm_id,
  isCandidateManager
) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];

  const BusinessManagersQuery = !isCandidateManager
    ? `bm_ids:  [${cmbm_id}],`
    : BusinessManagersFinal.length > 0
    ? `bm_ids: [${BusinessManagersFinal}] ,`
    : "";

  const CandidateManagersQuery = isCandidateManager
    ? `cm_ids: [${cmbm_id}],`
    : CandidateManagersFinal.length > 0
    ? `cm_ids: [${CandidateManagersFinal}],`
    : "";

  const StageManagersFinal = Array.isArray(filterData?.StageManagers)
    ? filterData?.StageManagers?.map((val) => val?.value)
    : [];
  const StageManagersQuery =
    StageManagersFinal.length > 0 ? `stage:"${StageManagersFinal}",` : "";

  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const data = await AxiosClient.post("", {
    query: `{
    reports {
      active_pipeline(filters: {
        ${positionQuery}
        ${focusQuery}
        ${BusinessManagersQuery}
        ${CandidateManagersQuery}
        ${StageManagersQuery}
        ${clientManagersQuery}
      }) {
        data {
          first_name
          
        }
      }
    }
  }`,
  });

  return data;
};

export const getCandidateProfileData = async (candidateId = "", language) => {
  const locale = `locale: ${language},`;
  const data = await AxiosClient.post("", {
    query: `query{
        candidates {
        profile(candidate_id: ${candidateId}, ${locale}) {  
        bio      
        birthday
        candidate_id
        current_employer
        current_title
        education_history
        english
        facebook_url
        first_name
        github_url
        japanese
        kanji_first
        kanji_last
        last_name
        linkedin_url
        outreach_url
        salary
        salary_breakdown
        trello_url
        twitter_url
        work_history
        zoho_url
        }
        }
        }`,
  });

  return data;
};

export const getCMNotes = async (candidateId) => {
  const data = await AxiosClient.post("", {
    query: `query CMNotes {
      candidates {
        cm_notes(candidate_id: ${candidateId}) {
          id
          candidate_id
          title
          content
          created_at
          author {
            id
            first_name
            last_name
            email
            role
          }
        }
      }
    }`,
  });

  return data;
};

export const createNote = async (noteDetail = {}) => {
  const candidateIdQuery = `candidate_id : "${noteDetail.candidate_id}"`;
  const titleQuery = `title : "${noteDetail.title}"`;
  const contentQuery = `content : "${noteDetail.content.replace(
    /\n/g,
    "\\n"
  )}"`;

  const data = await AxiosClient.post("", {
    query: `mutation CreateCMNote{
      candidates {
        save_cm_note(payload: {
          ${candidateIdQuery},
          ${titleQuery},
          ${contentQuery}
        }) {
          id
          candidate_id
          title
          content
          created_at
          author {
            id
            first_name
            last_name
            email
            role
          }
        }
      }
    }`,
  });

  return data;
};

export const updateNote = async (noteId, noteDetail = {}) => {
  const candidateIdQuery = `candidate_id : "${noteDetail.candidate_id}"`;
  const titleQuery = `title : "${noteDetail.title}"`;
  const contentQuery = `content : "${noteDetail.content.replace(
    /\n/g,
    "\\n"
  )}"`;

  const data = await AxiosClient.post("", {
    query: `
    mutation UpdateNote {
      candidates {
        save_cm_note(payload: {
          ${candidateIdQuery},
          ${titleQuery},
          ${contentQuery}
        }, note_id: ${noteId}) {
          id
          candidate_id
          title
          content
          created_at
          author {
            id
            first_name
            last_name
            email
            role
          }
        }
      }
    }`,
    variables: {
      payload: { ...noteDetail },
    },
  });
  return data;
};

export const deleteNote = async (noteId = "") => {
  const data = await AxiosClient.post("", {
    query: `mutation DeleteCMNote {
      candidates {
        delete_cm_note(note_id: "${noteId}")
      }
    }`,
  });
  return data;
};

export const fetchUniversalQuestions = async (candidateId) => {
  const data = await AxiosClient.post("", {
    query: `query Candidates {
    candidates {
        question_answers(candidate_id: "${candidateId}") {
            universal {
                id
                candidate_id
                created_at
                question
                question_hash
                text
                updated_at
            }
        }
    }
}`,
  });

  return data;
};

export const fetchClientQuestions = async (candidateId, clientId) => {
  const data = await AxiosClient.post("", {
    query: `query Candidates {
    candidates {
        question_answers(candidate_id: "${candidateId}", inquirer_id: "${clientId}") {
            client {
                id
                candidate_id
                created_at
                question
                question_hash
                text
                updated_at
                last_updated_by {
                    id
                    first_name
                    last_name
                    email
                    role
                }
            }
        }
    }
}
`,
  });

  return data;
};

export const fetchPositionQuestions = async (candidateId, positionId) => {
  const data = await AxiosClient.post("", {
    query: `query Candidates {
    candidates {
        question_answers(candidate_id: "${candidateId}", inquirer_id: "${positionId}") {
            position {
                id
                candidate_id
                created_at
                question
                question_hash
                text
                updated_at
            }
        }
    }
}
`,
  });

  return data;
};

export const getJobList = async (filterData, Clients, language, pagination) => {
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";

  const ClientQuery = Clients?.value ? `client_id: "${Clients?.value}",` : "";
  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id: "${filterData?.ClientManagers?.value}",`
    : "";

  const industry = filterData?.Industry?.value
    ? `industry: "${filterData?.Industry?.value}",`
    : "";

  const remotePolicy = filterData?.RemotePolicy?.value
    ? `remote_policy: ${filterData?.RemotePolicy?.value},`
    : "";

  const visibility = filterData?.Visibility?.value
    ? `visibility: ${filterData?.Visibility?.value},`
    : "";

  const status =
    filterData?.Status.length > 0
      ? `status: "${filterData?.Status?.map((val) => val?.value)}",`
      : "";

  const requiredLanguages = filterData?.RequiredLanguages?.value
    ? `required_languages: ${filterData?.RequiredLanguages?.value},`
    : "";

  const locale = `locale: ${language},`;

  const activeSearching = filterData?.ActiveSearching
    ? `active_searching: ${filterData?.ActiveSearching}`
    : "";

  const startDate = filterData?.startDate
    ? `start_date: "${filterData.startDate.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData.endDate.toISOString()}",`
    : "";

  const filters = `{
    ${positionQuery}
    ${focusQuery}
    ${ClientQuery}
    ${clientManagersQuery}
    ${industry}
    ${remotePolicy}
    ${visibility}
    ${status}
    ${requiredLanguages}
    ${activeSearching}
    ${startDate}
    ${endDate}
  }`;

  const data = await AxiosClient.post("", {
    query: `query {
      positions {
        client_jobs(
          filters:  ${filters},
          ${locale}
          page: {
            limit: ${pagination?.limit},
            offset: ${pagination?.offset},
          }
        ) {
          count
          has_more
          data {
            client_id
            client_name
            focus
            industry
            location
            position_id
            position_name
            position_name_ja
            remote_policy
            role
            company_stage
            required_languages
            active_searches
            total_searches
          }
        }
      }
      dashboard {
        client_jobs_counts(filters: ${filters}) {
          total_positions
          positions_worked
          positions_working
        }
      }
    }`,
  });

  return data;
};

export const getJobDetails = async (positionId) => {
  const data = await AxiosClient.post("", {
    query: `query { 
      positions {
        client_job_detail(position_id: "${positionId}") {
          client_logo
          description
          tech_stack
        }
      }
    }`,
  });
  return data;
};

export const getCandidateList = async (filterData, pagination, language) => {
  const companyName = filterData?.CompanyName?.value
    ? `company_name: ${JSON.stringify(filterData?.CompanyName)},`
    : "";

  const englishLevel = filterData?.EnglishLevel?.value
    ? `english_level: ${filterData?.EnglishLevel?.value},`
    : "";

  const japaneseLevel = filterData?.JapaneseLevel?.value
    ? `japanese_level: ${filterData?.JapaneseLevel?.value},`
    : "";

  const background = filterData?.Background?.value
    ? `background: ${filterData?.Background?.value},`
    : "";

  const tags = filterData?.Tags?.value
    ? `tags: ${filterData?.Tags?.value},`
    : "";

  const title = filterData?.Title?.value
    ? `title: ${filterData?.Title?.value},`
    : "";

  const locale = `locale: ${language},`;

  const data = await AxiosClient.post("", {
    query: `query {
      candidates {
        list(
          filters:  {
            ${companyName}
            ${englishLevel}
            ${japaneseLevel}
            ${background}
            ${tags}
            ${title}
          },
          page: {
            limit: ${pagination?.limit},
            offset: ${pagination?.offset},
          },
          ${locale}
        ) {    
            count
            data {
              bio
              candidate_id
              certifications
              current_employer
              current_title
              education_history
              english_level_num
              english_level_str
              background
              facebook_url
              first_name
              github_url
              japanese_level_num
              japanese_level_str
              kanji_first
              kanji_last
              last_name
              linkedin_url
              outreach_url
              salary
              salary_breakdown
              skills
              tags
              trello_url
              twitter_url
              work_history
              zoho_url
              birthday
            }
            has_more
        }
      }
    }`,
  });

  return data;
};

export const getPitchedCandidates = async (positionId, language) => {
  const locale = `locale: ${language},`;
  const data = await AxiosClient.post("", {
    query: `query { 
      positions {
        pitch_list (
          filters: { position_id: "${positionId}"},
          ${locale}
        ) {
              data {
                current_employer
                current_title
                first_name
                last_name
                app_position {
                  client_name
                  current_stage
                  focus
                  last_updated
                  position_name
                  position_id
                  role
                  pitch_response {
                    reason
                    response
                  }
                  candidate_manager {
                    id
                    first_name
                    last_name
                    email
                  }
                  stage_history {
                    comments {
                      created_at
                      text
                    }
                    created_at
                    other_applications
                    stage
                    updated_at
                  }
                } 
                birthday
                education_history
                english
                facebook_url
                github_url
                japanese
                kanji_first
                kanji_last
                linkedin_url
                salary
                salary_breakdown
                twitter_url
                zoho_url
                trello_url
                outreach_url
                work_history
                candidate_id
                last_activity_date
                bio
              }
          }
      }
    }`,
  });
  return data;
};

export const getTargetCandidates = async (positionId, pagination, language) => {
  const locale = `locale: ${language},`;
  const data = await AxiosClient.post("", {
    query: `query { 
      positions {
        target_list (
          position_id: "${positionId}",
          page: {
            limit: ${pagination?.limit},
            offset: ${pagination?.offset},
          },
          ${locale}
        ) {
            count
            data {
              candidate_id
              first_name
              last_name
              kanji_last
              kanji_first
              current_employer
              current_title
              salary
              salary_breakdown
              english
              japanese
              linkedin_url
              twitter_url
              facebook_url
              github_url
              birthday  # to calculate age
              education_history
              work_history
              zoho_url
              outreach_url
              bio
              first_targeted
              last_targeted
            }
            has_more
          }
        }
      }`,
  });
  return data;
};

export const getUsers = async (clientId, pagination) => {
  const client_id = clientId ? `client_id: "${clientId}"` : "";
  const next_token = pagination?.nextToken
    ? `, next_token: "${pagination?.nextToken}"`
    : "";
  const data = await AxiosClient.post("", {
    query: `query ListUsers {
      admin {
        list_users (
          ${client_id}
          limit: ${pagination?.limit},
          ${next_token}
        ) {
          data {
            confirmation_status
            created
            email
            enabled
            first_name
            id
            is_admin
            last_login
            last_name
            phone_number
            title
            updated
          }
          next_token
        }
      }
    }`,
  });
  return data;
};

export const updateUserProfile = async (payload) => {
  const data = await AxiosClient.post("", {
    query: `mutation SaveUserProfile {
      users {
        update_user_profile(
          payload: {
            first_name: "${payload?.first_name}",
            last_name: "${payload?.last_name}",
            phone_number: "${payload?.phone_number}",
            title: "${payload?.title}",
          }
        ) {
          confirmation_status
          created
          email
          enabled
          first_name
          id
          is_admin
          last_login
          last_name
          phone_number
          title
          updated
        }
      }
    }`,
  });
  return data;
};

export const getBillingsReport = async (filterData, pagination, language) => {
  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate?.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate?.toISOString()}",`
    : "";
  const unit = filterData?.unit ? `unit: ${filterData.unit},` : ``;
  const count = filterData?.count ? `count: ${filterData?.count},` : ``;
  const applyToStart = filterData?.ApplyToStart
    ? `apply_to_start: ${filterData?.ApplyToStart},`
    : "";
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];
  const BusinessManagersQuery =
    BusinessManagersFinal.length > 0
      ? `bm_ids: [${BusinessManagersFinal}],`
      : "";
  const CandidateManagersQuery =
    CandidateManagersFinal.length > 0
      ? `cm_ids: [${CandidateManagersFinal}],`
      : "";

  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const background = filterData?.Background
    ? `background: ${filterData?.Background},`
    : "";

  const sideBarFilter = `
    ${applyToStart}
    ${positionQuery}
    ${focusQuery}
    ${BusinessManagersQuery}
    ${CandidateManagersQuery}
    ${clientManagersQuery}
    ${background}
  `;

  const candidatesQuery = !pagination?.limit
    ? ""
    : `billing_totals_candidates_list(
        filters: {
          ${sideBarFilter}     
          ${startDate} 
          ${endDate}
        },
        locale: ${language},
        page: {
          limit: ${pagination?.limit},
          offset: ${pagination?.offset},
        }
      ) {
          count
          data {
            app_position {
              current_stage_created
              business_manager {
                id
                first_name
                last_name
                email
                role
              }
              candidate_manager {
                id
                first_name
                last_name
                email
                role
              }
              client_name
              position_name
              position_id
              stage_history {
                created_at
                stage
                updated_at
                other_applications
                pitch_response {
                  reason
                  response
                }
                comments {
                  commenter {
                    email
                    first_name
                    id
                    last_name
                    role
                  }
                  created_at
                  text
                }
              }
              offer_details {
                fee
                salary
                start_date
                fee_percentage
              }
              pitch_response {
                reason
                response
              }
              current_stage
              focus
              last_updated
              role
            }
            bio
            birthday
            candidate_id
            current_employer
            current_title
            education_history
            english
            facebook_url
            first_name
            first_targeted
            github_url
            japanese
            kanji_first
            kanji_last
            last_activity_date
            last_activity_type
            last_name
            last_targeted
            linkedin_url
            outreach_url
            salary
            salary_breakdown
            trello_url
            twitter_url
            work_history
            zoho_url
          }
          has_more
        }`;

  const data = await AxiosClient.post("", {
    query: `query BillingReport {
        reports {
          billing_totals(
            interval: { 
              ${startDate} 
              ${endDate}
              ${unit}
              ${count}
            },
            filters: { 
              ${sideBarFilter}
            }
          ) {
            benchmark
            intervals {
              data {
                total_billing
                total_offers
              }
              range {
                interval_end
                interval_start
              }
            }
          }
        ${candidatesQuery}
        }
      }`,
  });

  return data;
};

export const getBillingsReportLeaderboardData = async (
  filterData,
  language,
  cmbm_id,
  isCandidateManager
) => {
  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate?.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate?.toISOString()}",`
    : "";
  const applyToStart = filterData?.ApplyToStart
    ? `apply_to_start: ${filterData?.ApplyToStart},`
    : "";
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";
  const BusinessManagersFinal = Array.isArray(filterData?.BusinessManagers)
    ? filterData?.BusinessManagers.some((val) => val.value.trim() === "*")
      ? filterData?.BusinessManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.BusinessManagers?.map((val) => val.value)
    : [];
  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];
  const BusinessManagersQuery = !isCandidateManager
    ? `bm_ids:  [${cmbm_id}],`
    : BusinessManagersFinal.length > 0
    ? `bm_ids: [${BusinessManagersFinal}] ,`
    : "";
  const CandidateManagersQuery = isCandidateManager
    ? `cm_ids: [${cmbm_id}],`
    : CandidateManagersFinal.length > 0
    ? `cm_ids: [${CandidateManagersFinal}],`
    : "";
  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";
  const background = filterData?.Background
    ? `background: ${filterData?.Background},`
    : "";
  const sideBarFilter = `
    ${applyToStart}
    ${positionQuery}
    ${focusQuery}
    ${BusinessManagersQuery}
    ${CandidateManagersQuery}
    ${clientManagersQuery}
    ${background}
  `;

  const candidatesQuery = `billing_totals_candidates_list(
      filters: {
        ${sideBarFilter}
        ${startDate}
        ${endDate}
      },
      locale: ${language},
    ) {
        count
        data {
          app_position {
            current_stage_created
            business_manager {
              id
              first_name
              last_name
              email
              role
            }
            candidate_manager {
              id
              first_name
              last_name
              email
              role
            }
            client_name
            position_name
            position_id
            stage_history {
              created_at
              stage
              updated_at
              other_applications
              pitch_response {
                reason
                response
              }
              comments {
                commenter {
                  email
                  first_name
                  id
                  last_name
                  role
                }
                created_at
                text
              }
            }
            offer_details {
              fee
              salary
              start_date
              fee_percentage
            }
            pitch_response {
              reason
              response
            }
            current_stage
            focus
            last_updated
            role
          }
          bio
          birthday
          candidate_id
          current_employer
          current_title
          education_history
          english
          facebook_url
          first_name
          first_targeted
          github_url
          japanese
          kanji_first
          kanji_last
          last_activity_date
          last_activity_type
          last_name
          last_targeted
          linkedin_url
          outreach_url
          salary
          salary_breakdown
          trello_url
          twitter_url
          work_history
          zoho_url
        }
        has_more
      }`;

  const data = await AxiosClient.post("", {
    query: `query BillingReport {
          reports {
            ${candidatesQuery}
          }
        }`,
  });

  return data;
};

export const getMeetingConversionReport = async (
  filterData,
  pagination,
  language
) => {
  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate?.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate?.toISOString()}",`
    : "";
  const unit = filterData?.unit ? `unit: ${filterData.unit},` : ``;
  const count = filterData?.count ? `count: ${filterData?.count},` : ``;
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];
  const CandidateManagersQuery =
    CandidateManagersFinal.length > 0
      ? `cm_ids: [${CandidateManagersFinal}],`
      : "";

  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const MailboxesFinal = Array.isArray(filterData?.Mailboxes)
    ? filterData?.Mailboxes.some((val) => val.value.trim() === "*")
      ? filterData?.Mailboxes?.filter((val) => val.value.trim() !== "*").map(
          (val) => val.value
        )
      : filterData?.Mailboxes?.map((val) => val.value)
    : [];
  const mailboxesQuery =
    MailboxesFinal.length > 0 ? `mailboxes: [${MailboxesFinal}],` : "";

  const DomainsFinal = Array.isArray(filterData?.Domains)
    ? filterData?.Domains.some((val) => val.value.trim() === "*")
      ? filterData?.Domains?.filter((val) => val.value.trim() !== "*").map(
          (val) => val.value
        )
      : filterData?.Domains?.map((val) => val.value)
    : [];
  const domainsQuery =
    DomainsFinal.length > 0 ? `domains: [${DomainsFinal}],` : "";

  const metQuery = filterData?.Met?.value
    ? `met: ${filterData?.Met?.value},`
    : "";
  const genericQuery = filterData?.Generic?.value
    ? `generic: ${filterData?.Generic?.value},`
    : "";
  const languageQuery = filterData?.Language?.value
    ? `language: "${filterData?.Language?.value}",`
    : "";
  const abTestString = filterData?.AbString
    ? `ab_test_string: "${filterData?.AbString}",`
    : "";
  const tagsQuery = filterData?.Tags ? `tags: "${filterData?.Tags}",` : "";
  const background = filterData?.Background?.value
    ? `background: "${filterData?.Background?.value}",`
    : "";

  const filter = `
    ${positionQuery}
    ${focusQuery}
    ${CandidateManagersQuery}
    ${clientManagersQuery}
    ${mailboxesQuery}
    ${domainsQuery}
    ${metQuery}
    ${genericQuery}
    ${languageQuery}
    ${abTestString}
    ${tagsQuery}
    ${background}
  `;

  const candidateQuery = pagination?.limit
    ? `meeting_conversion_candidates_list (
          filters: {
            ${startDate} 
            ${endDate}
            ${filter}
          },
          locale: ${language},
          page: {
            limit: ${pagination?.limit},
            offset: ${pagination?.offset},
          }
        ) {
          count
          data {
            app_position {
              current_stage_created
              business_manager {
                id
                first_name
                last_name
                email
                role
              }
              candidate_manager {
                id
                first_name
                last_name
                email
                role
              }
              client_name
              position_name
              position_id
              stage_history {
                created_at
                stage
                updated_at
                other_applications
                pitch_response {
                  reason
                  response
                }
                comments {
                  commenter {
                    email
                    first_name
                    id
                    last_name
                    role
                  }
                  created_at
                  text
                }
              }
              offer_details {
                fee
                salary
                start_date
                fee_percentage
              }
              pitch_response {
                reason
                response
              }
              current_stage
              focus
              last_updated
              role
            }
            bio
            birthday
            candidate_id
            current_employer
            current_title
            education_history
            english
            facebook_url
            first_name
            first_targeted
            github_url
            japanese
            kanji_first
            kanji_last
            last_activity_date
            last_activity_type
            last_name
            last_targeted
            linkedin_url
            outreach_url
            salary
            salary_breakdown
            trello_url
            twitter_url
            work_history
            zoho_url
          }
          has_more
        }`
    : "";

  const data = await AxiosClient.post("", {
    query: `query MeetingConversionInterval {
      reports {
        meeting_conversion (
          filters: { 
            ${filter} 
          },
          interval: {
            ${startDate} 
            ${endDate}
            ${unit}
            ${count}
          },
        ) {
          benchmark
          intervals {
            data {
              total_leads
              total_emails
              replied
              positive_replies
              num_meetings
              num_pitches
              num_target_pitches
              percent_replied
              percent_positive
              percent_meetings
              pitches_per_meeting
              target_pitches_per_meeting
            }
            range {
              interval_end
              interval_start
            }
          }
        }
        ${candidateQuery}
      }
    }`,
  });
  return data;
};

export const getMeetingConversionReportLeaderboardData = async (
  filterData,
  language,
  cmbm_id,
  isCandidateManager
) => {
  const startDate = filterData?.startDate
    ? `start_date: "${filterData?.startDate?.toISOString()}",`
    : "";
  const endDate = filterData?.endDate
    ? `end_date: "${filterData?.endDate?.toISOString()}",`
    : "";
  const unit = filterData?.unit ? `unit: ${filterData.unit},` : ``;
  const count = filterData?.count ? `count: ${filterData?.count},` : ``;
  const positionQuery = filterData?.Positions?.value
    ? `role: "${filterData?.Positions?.value}",`
    : "";
  const focusQuery = filterData?.Speciality?.value
    ? `focus: "${filterData?.Speciality?.value}",`
    : "";

  const CandidateManagersFinal = Array.isArray(filterData?.CandidateManagers)
    ? filterData?.CandidateManagers.some((val) => val.value.trim() === "*")
      ? filterData?.CandidateManagers?.filter(
          (val) => val.value.trim() !== "*"
        ).map((val) => val.value)
      : filterData?.CandidateManagers?.map((val) => val.value)
    : [];
  const CandidateManagersQuery = isCandidateManager
    ? `cm_ids: [${cmbm_id}],`
    : CandidateManagersFinal.length > 0
    ? `cm_ids: [${CandidateManagersFinal}],`
    : "";

  const clientManagersQuery = filterData?.ClientManagers?.value
    ? `client_id:"${filterData?.ClientManagers?.value}",`
    : "";

  const MailboxesFinal = Array.isArray(filterData?.Mailboxes)
    ? filterData?.Mailboxes.some((val) => val.value.trim() === "*")
      ? filterData?.Mailboxes?.filter((val) => val.value.trim() !== "*").map(
          (val) => val.value
        )
      : filterData?.Mailboxes?.map((val) => val.value)
    : [];
  const mailboxesQuery =
    MailboxesFinal.length > 0 ? `mailboxes: [${MailboxesFinal}],` : "";

  const DomainsFinal = Array.isArray(filterData?.Domains)
    ? filterData?.Domains.some((val) => val.value.trim() === "*")
      ? filterData?.Domains?.filter((val) => val.value.trim() !== "*").map(
          (val) => val.value
        )
      : filterData?.Domains?.map((val) => val.value)
    : [];
  const domainsQuery =
    DomainsFinal.length > 0 ? `domains: [${DomainsFinal}],` : "";

  const metQuery = `met: ${filterData?.Met?.value || false},`;
  const genericQuery = `generic: ${filterData?.Met?.value || false},`;
  const languageQuery = filterData?.Language?.value
    ? `language: "${filterData?.Language?.value}",`
    : "";
  const abTestString = filterData?.AbString
    ? `ab_test_string: "${filterData?.AbString}",`
    : "";
  const tagsQuery = filterData?.Tags ? `tags: "${filterData?.Tags}",` : "";
  const background = filterData?.Background
    ? `background: "${filterData?.Background}",`
    : "";

  const filter = `
    ${positionQuery}
    ${focusQuery}
    ${CandidateManagersQuery}
    ${clientManagersQuery}
    ${mailboxesQuery}
    ${domainsQuery}
    ${metQuery}
    ${genericQuery}
    ${languageQuery}
    ${abTestString}
    ${tagsQuery}
    ${background}
  `;

  const data = await AxiosClient.post("", {
    query: `query MeetingConversionInterval {
      reports {
        meeting_conversion (
          filters: { 
            ${filter} 
          },
          interval: {
            ${startDate} 
            ${endDate}
            ${unit}
            ${count}
          },
        ) {
          benchmark
          intervals {
            data {
              total_leads
              total_emails
              replied
              positive_replies
              num_meetings
              num_pitches
              num_target_pitches
              percent_replied
              percent_positive
              percent_meetings
              pitches_per_meeting
              target_pitches_per_meeting
            }
            range {
              interval_end
              interval_start
            }
          }
        }
        meeting_conversion_candidates_list (
          filters: {
            ${startDate} 
            ${endDate}
            ${filter}
          },
          locale: ${language},
        ) {
          count
          data {
            app_position {
              current_stage_created
              business_manager {
                id
                first_name
                last_name
                email
                role
              }
              candidate_manager {
                id
                first_name
                last_name
                email
                role
              }
              client_name
              position_name
              position_id
              stage_history {
                created_at
                stage
                updated_at
                other_applications
                pitch_response {
                  reason
                  response
                }
                comments {
                  commenter {
                    email
                    first_name
                    id
                    last_name
                    role
                  }
                  created_at
                  text
                }
              }
              offer_details {
                fee
                salary
                start_date
                fee_percentage
              }
              pitch_response {
                reason
                response
              }
              current_stage
              focus
              last_updated
              role
            }
            bio
            birthday
            candidate_id
            current_employer
            current_title
            education_history
            english
            facebook_url
            first_name
            first_targeted
            github_url
            japanese
            kanji_first
            kanji_last
            last_activity_date
            last_activity_type
            last_name
            last_targeted
            linkedin_url
            outreach_url
            salary
            salary_breakdown
            trello_url
            twitter_url
            work_history
            zoho_url
          }
          has_more
        }
      }
    }`,
  });
  return data;
};

export const getDomainHealthData = async () => {
  const data = await AxiosClient.post("", {
    query: `query DomainHealth {
      reports {
        domain_health {
          address
          positive_replies
          total_replies
          unique_replies
        }
      }
    }`,
  });
  return data;
};
