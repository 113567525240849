import React, { createContext, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { getLoggedInUserData } from "services/FetchApiData";

const initialUserContextValue = {
  User: {},
  loggedInUserData: {},
  minimize: false,
  setMinimize: () => {},
  showSidebar: true,
  setShowSidebar: () => {},
  selectedGraph: "Pie Chart",
  setSelectedGraph: () => {},
  language: "en",
  setLanguage: () => {},
  fetchUserData: () => {},
  isUserLoading: false,
  clearUserContext: () => {},
  userGroup: [],
  customReports: [],
  setCustomReports: () => {},
};

export const UserContext = createContext(initialUserContextValue);

export const UserProvider = ({ children }) => {
  const localUserLanguage = localStorage.getItem("language");

  const [loggedInUserData, setLoggedInUserData] = useState({});
  const [minimize, setMinimize] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [selectedGraph, setSelectedGraph] = useState("Pie Chart");
  const [language, setLanguage] = useState(() => {
    return ["en", "ja"].includes(localUserLanguage) ? localUserLanguage : "en";
  });
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [customReports, setCustomReports] = useState([]);

  const { user: User } = useAuthenticator((context) => [context.user]);
  const group =
    User?.signInUserSession?.idToken?.payload["cognito:groups"] ?? [];

  const fetchLoggedInUserData = async (language) => {
    try {
      setIsUserLoading(true);
      const userData = await getLoggedInUserData(language);
      setLoggedInUserData(userData.data.data.me);
      setCustomReports(userData.data.data.me.saved_reports);
    } catch (error) {
      console.log(error);
    } finally {
      setIsUserLoading(false);
    }
  };

  function clearUserContext() {
    setLoggedInUserData({});
    setMinimize(false);
    setShowSidebar(true);
    setSelectedGraph("Pie Chart");
    setLanguage("en");
    setIsUserLoading(false);
    setCustomReports([]);
  }

  return (
    <UserContext.Provider
      value={{
        User,
        loggedInUserData,
        minimize,
        setMinimize,
        showSidebar,
        setShowSidebar,
        selectedGraph,
        setSelectedGraph,
        language,
        setLanguage,
        fetchUserData: fetchLoggedInUserData,
        isUserLoading,
        clearUserContext,
        userGroup: group,
        customReports,
        setCustomReports,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
