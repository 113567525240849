import { UserContext } from "context/UserContext";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

const AdminPermission = ({ children, isFallback = true }) => {
  const navigate = useNavigate();
  const {
    isUserLoading,
    loggedInUserData: { is_admin },
  } = useContext(UserContext);

  if (isUserLoading) return <></>;
  if (is_admin) return <>{children}</>;
  if (isFallback) setTimeout(() => navigate("/"), 0);
  return null;
};

export default AdminPermission;
