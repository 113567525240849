import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import CustomFilter from "components/Reports/CustomFilter";
import { UserContext } from "context/UserContext";
import { convertGridColumnMenu, uuidv4 } from "helpers/ReusableFunctions";
import { useContext, useEffect, useRef, useState } from "react";
import { getDomainHealthData } from "services/FetchApiData";

const sortableColumn = true;
const pinnableColumn = true;

const DomainHealth = () => {
  const apiRef = useGridApiRef();
  const isDataFetch = useRef(false);

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const columns = [
    {
      field: "address",
      headerName: "Mailbox / Domain",
      width: 600,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "total_replies",
      headerName: "Total Replies",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "unique_replies",
      headerName: "Unique Replies",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
    {
      field: "positive_replies",
      headerName: "Positive Replies",
      width: 200,
      sortable: sortableColumn,
      pinnable: pinnableColumn,
      filterOperators: filterOperators,
    },
  ];

  const [domainHealthData, setDomainHealthData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [columnsOrder, setColumnsOrder] = useState(columns);

  const { language } = useContext(UserContext);

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  const fetchDomainHealthData = async () => {
    if (isDataFetch.current) return;
    try {
      isDataFetch.current = true;
      setIsLoading(true);
      const response = await getDomainHealthData();
      setDomainHealthData(
        response?.data?.data?.reports?.domain_health?.map((el) => ({
          ...el,
          id: uuidv4(),
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      isDataFetch.current = false;
    }
  };

  useEffect(() => {
    fetchDomainHealthData();
    return () => setDomainHealthData([]);
  }, []);

  return (
    <div className="candidates">
      <h4>Domain Health</h4>
      <div className="card mt-4">
        <div className="card-body">
          <div
            className="cstm-mui-datagrid"
            style={{ height: 500, width: "100%" }}
          >
            <DataGridPro
              loading={isLoading}
              rows={domainHealthData}
              columns={columnsWithOperators}
              slotProps={{
                filterPanel: {
                  sx: { maxWidth: "calc(90vw - 24px)" },
                },
              }}
              getRowId={(row) => row?.id}
              onColumnOrderChange={(c) => {
                setColumnsOrder((prevCols) => {
                  const newCols = [...prevCols];
                  newCols.splice(c.oldIndex, 1);
                  newCols.splice(c.targetIndex, 0, c.column);
                  return newCols;
                });
              }}
              initialState={{
                ...columns.initialState,
                columns: columns.initialState?.columns,
              }}
              localeText={convertGridColumnMenu(language)}
              apiRef={apiRef}
              hideFooter
              rowsLoadingMode={"server"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainHealth;
